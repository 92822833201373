import React, { useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [quantities, setQuantities] = useState(1);
  const { addToCart } = useContext(CartContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("description");
  const [selectedSize, setSelectedSize] = useState(null);

  const handleAddToCart = () => {
    if (!selectedSize) {
      alert("Please select a size!");
      return;
    }
    addToCart(product.id, quantities, selectedSize);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/cartpage");
  };
  if (!product) {
    return <div>Product not found</div>;
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleClick = (size) => {
    setSelectedSize(size);
  };

  return (
    <div className="main-container">
      <Header />
      <div className="shopDiv">
        <div className="product-detail">
          <div className="product-images">
            <div className="carousel">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.name}`}
                  style={{ display: index === currentIndex ? "block" : "none" }}
                />
              ))}
            </div>
            <div className="carousel-buttons">
              {product.images.map((image, index) => (
                <button key={index} onClick={() => setCurrentIndex(index)}>
                  <img src={image} alt={`${product.name}`} />
                </button>
              ))}
            </div>
          </div>
          <div className="product-info">
            <h3 className="sldo__name">{product.name}</h3>
            <h4 className="stock-in">¥{product.price}</h4>
            <div className="product__policies rte" data-product-policies="">
              税込
            </div>
            <div className="rio-product-option-title">サイズ</div>
            <div className="sldo__size_div">
              {product.size.map((size, index) => (
                <div
                  className={`size__item ${
                    selectedSize === size ? "selected" : ""
                  }`}
                  key={index}
                  onClick={() => handleClick(size)}
                >
                  {size}
                </div>
              ))}
            </div>
            <div className="product__policies11 rte" data-product-policies="">
              個数
            </div>
            <input
              type="number"
              value={quantities}
              min="1"
              onChange={(e) =>
                setQuantities(Math.max(1, parseInt(e.target.value, 10)))
              }
              className="quantity-input"
            />

            <div className="btnDiv">
              <button className="add-to-cart" onClick={handleAddToCart}>
                カートに追加する
              </button>
            </div>
            <div className="item-des-color">
              <div className="tab-set__tab-group">
                <div className="tab-set__tab-window">
                  <div
                    className={`tab-set__tab ${
                      activeTab === "description" ? "is-active" : ""
                    }`}
                    onClick={() => handleTabChange("description")}
                  >
                    サイズ・詳細
                  </div>
                  <div
                    className={`tab-set__tab ${
                      activeTab === "colours" ? "is-active" : ""
                    }`}
                    onClick={() => handleTabChange("colours")}
                  >
                    よくある質問
                  </div>
                </div>
              </div>
              <div className="tab-set__body-group">
                <div
                  className={`tab-set__body ${
                    activeTab === "description" ? "is-active" : ""
                  }`}
                >
                  <img alt="" src={product.sizeImg} />
                </div>
                <div
                  className={`colors-item ${
                    activeTab === "colours" ? "is-active" : ""
                  }`}
                >
                  <ul className="describe__ul">
                    {product.description.map((description, index) => (
                      <li key={index}>{description}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
