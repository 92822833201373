import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";

function Contact() {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const contactSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!name) newErrors.name = "Enter a name";
    if (!mail) newErrors.mail = "Enter an email";
    if (!phone) newErrors.phone = "Enter a phone number";
    if (!message) newErrors.message = "Enter a message";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    } else {
      alert("Send Success!");
      setName("");
      setMail("");
      setPhone("");
      setMessage("");
    }
  };

  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div className="term-h1">
            <h1>お問い合わせ</h1>
          </div>
          <p>こんにちは、SLDO.でございます。</p>
          <p>
            ご質問などお気軽にお送りください。
            <br />
            営業時間内でございましたら、なるべく早くご返信させていただきます(^^♪
            <br />
            営業時間外でも、できる限り対応させていただきます。
            <br />
            夜遅い時間帯のお問い合わせは営業時間内にご返信いたします。
          </p>
          <p>
            LINEからもご相談いただけます！
            <br />
            画面右下の黒丸の吹き出しアイコンよりご利用ください(^^♪
          </p>
          <p>-----------------------------------------------------</p>
          <p>
            営業時間：月～金 9:00-18:00
            <br />
            電話番号：078-915-7660 (運営会社 クロコデザイン)
          </p>
          <p>
            お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合
          </p>
          <p>法令に基づき開示することが必要である場合</p>
          <div className="contact-form form-vertical">
            <form
              id="ContactForm"
              acceptCharset="UTF-8"
              className="contact-form"
              onSubmit={contactSubmit}
            >
              <div className="grid grid--half-gutters">
                <div className="grid__item medium-up--one-half">
                  <label htmlFor="ContactForm-name">名前</label>
                  <input
                    type="text"
                    id="ContactForm-name"
                    name="contact[名前]"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="grid__item medium-up--one-half">
                  <label htmlFor="ContactForm-email">
                    Eメール <span aria-hidden="true">*</span>
                  </label>
                  <input
                    type="email"
                    id="ContactForm-email"
                    name="contact[email]"
                    autoCorrect="off"
                    autoCapitalize="off"
                    required
                    aria-required="true"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                  />
                </div>
              </div>
              <label htmlFor="ContactForm-phone">電話番号</label>
              <input
                type="tel"
                id="ContactForm-phone"
                name="contact[電話番号]"
                pattern="[0-9\-]*"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <label htmlFor="ContactForm-message">メッセージ</label>
              <textarea
                rows="10"
                id="ContactForm-message"
                name="contact[メッセージ]"
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <input type="submit" className="btn" value="送信" />
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
