import React, { useContext, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import "../css/shop.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";

function Main() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="twoDiv">
          <img alt="" src="/head-foot/main1.webp" />
        </div>
        <div className="texttop2">一度着るとやめられないシリーズ</div>
        <div className="texttop3">
          <p>
            SLDO.オンラインストアにお越しいただきありがとうございます。
            <br />
            サイズ感など、ネット販売のご不安もあるかと存じますが、何かございましたら右下のボタンよりお気軽にお声がけください。
            <br />
            迅速に対応させていただきます。
          </p>
        </div>
        <div className="texttop4">
          予約商品と通常商品を含むご注文の配送について
        </div>
        <div className="texttop4">
          サイズのご交換・選び方 | ギフト包装 | お支払い方法 | 領収書について
        </div>
        <div className="texttop4">
          ご注文確認メールやお問い合わせメールの返信が届かない場合について
        </div>
        <div className="autumn_collection">
          <img alt="" src="/head-foot/main2.webp" />
        </div>
        <div
          id="shopify-section-1662364965370b96b8"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="title">
              <h2>
                <span>All items</span>
              </h2>
              <hr className="hr_marginb_75" />
            </div>
          </div>
        </div>
        <div className="mainBox">
          <div className="container-fluid">
            {/* top */}
            <div className="top-container">
              <div className="product-grid">
                {productData.map((brand) => (
                  <div className="product-category" key={brand.id}>
                    <div className="image-container">
                      <Link
                        to={`/shopDetails/${brand.id}`}
                        onClick={handleNavigate}
                      >
                        <img alt="" src={brand.images[0]} />
                      </Link>
                      <h2>{brand.name}</h2>
                      <div className="pp-pre">
                        <h4 className="red-price">
                          <strong>¥{brand.price}</strong>
                        </h4>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <Link
            to="/"
            className="btn"
            aria-label="ALL ITEMSコレクションの全製品を見る"
          >
            すべてを見る
          </Link>
        </div>
        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="text-center" style={{ margin: "2% 0" }}>
                <h2 className="title2">SHOP LIST</h2>
              </div>

              <ul className="lineup_flex">
                <li>
                  <Link to="/">
                    <img
                      src="https://cdn.shopify.com/s/files/1/0557/8591/4576/files/KOBE_13.jpg?v=1703248101"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="https://cdn.shopify.com/s/files/1/0557/8591/4576/files/KOBE_14.jpg?v=1703575456"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="https://cdn.shopify.com/s/files/1/0557/8591/4576/files/2023_SPRING_SUMMER_COLLECTION_11.jpg?v=1695819817"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                </li>
              </ul>
              <div className="text-center mt50" style={{ marginTop: "2%" }}>
                <Link to="/" className="btn">
                  すべてを見る
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="text-center">
                <h2 className="title2">News</h2>
              </div>

              <ul className="lineup_flex">
                <li>
                  <Link to="/">
                    <img
                      src="/head-foot/news1.webp"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-0-1620990567c819c487"
                  >
                    2024 AUTUMN WINTER COLLECTION
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="/head-foot/news2.webp"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-1-1620990567c819c487"
                  >
                    【お知らせ】夏季休業と配送のおしらせ
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="/head-foot/news3.webp"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-2-1620990567c819c487"
                  >
                    2024 NAVY WHITE COLLECTION
                  </h3>
                </li>
              </ul>

              <div className="text-center mt50" style={{ marginTop: "2%" }}>
                <Link to="/" className="btn">
                  すべてを見る
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <div className="text-center">
                <h2 className="title2">BLOG</h2>
              </div>

              <ul className="lineup_flex">
                <li>
                  <Link to="/">
                    <img
                      src="/head-foot/blog1.webp"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-0-1620990567c819c487"
                  >
                    2024 AUTUMN WINTER COLLECTION
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="/head-foot/blog2.jpg"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-1-1620990567c819c487"
                  >
                    【お知らせ】夏季休業と配送のおしらせ
                  </h3>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="/head-foot/blog3.webp"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                  <h3
                    className="article__title"
                    id="FeaturedBlogTitle-2-1620990567c819c487"
                  >
                    2024 NAVY WHITE COLLECTION
                  </h3>
                </li>
              </ul>

              <div className="text-center mt50" style={{ marginTop: "2%" }}>
                <Link to="/" className="btn">
                  すべてを見る
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          id="shopify-section-1662364965370b96b8"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="title" style={{ margin: "50px auto 0px" }}>
              <h2>
                <span>COORDINATE</span>
              </h2>
              <hr className="hr_marginb_75" />
            </div>
          </div>
        </div>

        <div
          id="shopify-section-b47d9df2-d926-46fe-a1e2-44aa689740e5"
          className="shopify-section"
        >
          <div className="custom-field">
            <div className="w1120 mb50">
              <ul className="lineup_flex1">
                <li>
                  <Link to="/">
                    <img
                      src="/head-foot/good1.webp"
                      alt="天野山カントリークラブ"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <img
                      src="/head-foot/good2.webp"
                      alt="有馬ロイヤルゴルフクラブ"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="/head-foot/good3.webp"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <div className="imgcap3">
                      <img
                        src="/head-foot/good4.webp"
                        alt="福岡雷山ゴルフ倶楽部"
                      />
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className="section-header text-center"
          style={{ marginBottom: "5%" }}
        >
          <h2 className="h2">
            最新のSLDO.コレクション、キャンペーン情報をいち早くお届けします
          </h2>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
