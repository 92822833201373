import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="botLeft">
          <div className="comImfo">
            <ul>
              <li>
                <Link to="/" onClick={toTop}>
                  ホーム
                </Link>
              </li>
              <li>
                <Link to="/particular" onClick={toTop}>
                  特定商取引法に基づく表記
                </Link>
              </li>
              <li>
                <Link to="/privacy" onClick={toTop}>
                  プライバシーポリシー
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={toTop}>
                  お問い合わせ
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="localization">
          <div className="localization_left">
            <div className="disclosure">JPY ¥</div>
            <ul>
              <li>
                <img alt="" src="/head-foot/card1.svg" />
              </li>
              <li>
                <img alt="" src="/head-foot/card2.svg" />
              </li>
              <li>
                <img alt="" src="/head-foot/card3.svg" />
              </li>
              <li>
                <img alt="" src="/head-foot/card4.svg" />
              </li>
              <li>
                <img alt="" src="/head-foot/card5.svg" />
              </li>
              <li>
                <img alt="" src="/head-foot/card6.svg" />
              </li>
              <li>
                <img alt="" src="/head-foot/card7.svg" />
              </li>
              <li>
                <img alt="" src="/head-foot/card8.svg" />
              </li>
              <li>
                <img alt="" src="/head-foot/card9.svg" />
              </li>
              <li>
                <img alt="" src="/head-foot/card10.svg" />
              </li>
              <li>
                <img alt="" src="/head-foot/card11.svg" />
              </li>
              <li>
                <img alt="" src="/head-foot/card12.svg" />
              </li>
              <li>
                <img alt="" src="/head-foot/card13.svg" />
              </li>
              <li>
                <img alt="" src="/head-foot/card14.svg" />
              </li>
            </ul>
          </div>
          <div className="localization_right">
            <div className="path_svg">
              <img
                width="35"
                height="35"
                src="https://img.icons8.com/ios-glyphs/50/FFFFFF/facebook.png"
                alt="facebook"
              />
              <img
                width="35"
                height="35"
                src="https://img.icons8.com/material-outlined/50/FFFFFF/instagram-new--v1.png"
                alt="instagram-new--v1"
              />
              <img
                width="35"
                height="35"
                src="https://img.icons8.com/sf-regular/50/FFFFFF/chat-message.png"
                alt="chat-message"
              />
            </div>
            <div className="small_div">
              <small>© 2024, SLDO.</small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
