const productData = [
  {
    id: 1,
    name: "サイドメッシュ切替ポロシャツ/BLACK",
    description: [
      "【身長・体重・ウエストサイズ、どのサイズがオススメですか？】",
      "・身長:168cm/体重:67kg/ウエスト:76cm/ガッチリ体型。",
      "→Mサイズでジャストサイズ。Lサイズでゆったりめ。",
      "・身長:170cm/体重:71kg/ガッチリ体型",
      "→Lサイズがオススメです。",
      "・身長:171cm/体重:62kg/細身ながら筋肉質でガッチリ/普段のサイズ:Mサイズ",
      "→Sサイズがオススメです。（上半身がかなりガッチリされてる場合はMサイズ）",
      "・身長:177cm/体重:69kg/やや細身体型",
      "→身長に合わせてLサイズがオススメです。（Mサイズでも身幅は問題ないですが丈が短めになります）",
      "・身長:180cm/体重:75kg/標準体型",
      "→XLサイズがオススメです。",
      "・身長:180cm/体重:80kg/ガッチリ体型",
      "→XXLサイズがオススメです。",
      "・身長:182cm/体重:105kg/ガッチリ体型",
      "→XXXLサイズがオススメです。",
      "【どんな生地感ですか？】",
      "→非常に薄い仕上がりです。通気性、吸水速乾性に優れたサラッとした生地感です。",
      "【汗かきで暑がりです。真夏のラウンドで着用できますか？】",
      "→汗っかきの方に多数ご愛用いただいております！肌に張り付かず、不快感なし。お手持ちのウエアの中で一番涼しいとのご感想もいただいております(^^♪",
      "【半袖モックネックと同じサイズでいいですか？】",
      "→細身体型、標準体型の方は同じサイズがオススメです。ガッチリとした体型で半袖モックネックが普段ご着用のサイズよりワンサイズ大きめの方は普段通りのサイズがオススメです。",
      "【ハーフパンツとリブパンツ、セットアップで着用できますか？】",
      "→もちろんです！生地は異なりますが、上下でカッコよく着ていただけるようにお作りしております。",
      "他ご質問などございましたら、LINEからもお問い合わせいただけます！ご相談画面右下の黒丸の吹き出しアイコンよりご利用ください(^^♪",
    ],
    price: "19800",
    sizeImg: "/sldo/size01.webp",
    size: [
      "XS",
      "〈残りわずか〉 S",
      "〈残りわずか〉M",
      "L",
      "XL",
      "〈残りわずか〉XXL",
      "〈残りわずか〉XXXL",
    ],
    images: [
      "/sldo/1001.jpg",
      "/sldo/1001a.jpg",
      "/sldo/1001b.jpg",
      "/sldo/1001c.jpg",
      "/sldo/1001d.jpg",
      "/sldo/1001e.jpg",
      "/sldo/1001f.jpg",
    ],
  },
  {
    id: 2,
    name: "サイドメッシュ切替ポロシャツ/WHITE",
    description: [
      "【身長・体重・ウエストサイズ、どのサイズがオススメですか？】",
      "・身長:168cm/体重:67kg/ウエスト:76cm/ガッチリ体型",
      "→Mサイズでジャストサイズ。Lサイズでゆったりめ。",
      "・身長:170cm/体重:71kg/ガッチリ体型",
      "→Lサイズがオススメです。",
      "・身長:171cm/体重:62kg/細身ながら筋肉質でガッチリ/普段のサイズ:Mサイズ",
      "→Sサイズがオススメです。（上半身がかなりガッチリされてる場合はMサイズ）",
      "・身長:177cm/体重:69kg/やや細身体型",
      "→身長に合わせてLサイズがオススメです。（Mサイズでも身幅は問題ないですが丈が短めになります）",
      "・身長:180cm/体重:75kg/標準体型",
      "→XLサイズがオススメです。",
      "・身長:180cm/体重:80kg/ガッチリ体型",
      "→XXLサイズがオススメです。",
      "・身長:182cm/体重:105kg/ガッチリ体型",
      "→XXXLサイズがオススメです。",
      "【どんな生地感ですか？】",
      "→非常に薄い仕上がりです。通気性、吸水速乾性に優れたサラッとした生地感です。",
      "【汗かきで暑がりです。真夏のラウンドで着用できますか？】",
      "→汗っかきの方に多数ご愛用いただいております！肌に張り付かず、不快感なし。お手持ちのウエアの中で一番涼しいとのご感想もいただいております(^^♪",
      "【半袖モックネックと同じサイズでいいですか？】",
      "→細身体型、標準体型の方は同じサイズがオススメです。ガッチリとした体型で半袖モックネックが普段ご着用のサイズよりワンサイズ大きめの方は普段通りのサイズがオススメです。",
      "【ハーフパンツとリブパンツ、セットアップで着用できますか？】",
      "→もちろんです！生地は異なりますが、上下でカッコよく着ていただけるようにお作りしております。",
      "他ご質問などございましたら、LINEからもお問い合わせいただけます！ご相談画面右下の黒丸の吹き出しアイコンよりご利用ください(^^♪",
    ],
    price: "19800",
    sizeImg: "/sldo/size02.webp",
    size: [
      "〈残りわずか〉XS",
      "〈完売〉 S",
      "〈残りわずか〉M",
      "〈完売〉L",
      "〈完売〉XL",
      "〈残りわずか〉XXL",
      "〈残りわずか〉XXXL",
    ],
    images: [
      "/sldo/1002.jpg",
      "/sldo/1002a.jpg",
      "/sldo/1002b.jpg",
      "/sldo/1002c.jpg",
      "/sldo/1002d.jpg",
      "/sldo/1002e.jpg",
    ],
  },
  {
    id: 3,
    name: "サイドメッシュ切替ポロシャツ/DARK NAVY",
    description: [
      "【身長・体重・ウエストサイズ、どのサイズがオススメですか？】",
      "・身長:168cm/体重:67kg/ウエスト:76cm/ガッチリ体型。",
      "→Mサイズでジャストサイズ。Lサイズでゆったりめ。",
      "・身長:170cm/体重:71kg/ガッチリ体型",
      "→Lサイズがオススメです。",
      "・身長:171cm/体重:62kg/細身ながら筋肉質でガッチリ/普段のサイズ:Mサイズ",
      "→Sサイズがオススメです。（上半身がかなりガッチリされてる場合はMサイズ）",
      "・身長:177cm/体重:69kg/やや細身体型",
      "→身長に合わせてLサイズがオススメです。（Mサイズでも身幅は問題ないですが丈が短めになります）",
      "・身長:180cm/体重:75kg/標準体型",
      "→XLサイズがオススメです。",
      "・身長:180cm/体重:80kg/ガッチリ体型",
      "→XXLサイズがオススメです。",
      "・身長:182cm/体重:105kg/ガッチリ体型",
      "→XXXLサイズがオススメです。",
      "【どんな生地感ですか？】",
      "→非常に薄い仕上がりです。通気性、吸水速乾性に優れたサラッとした生地感です。",
      "【汗かきで暑がりです。真夏のラウンドで着用できますか？】",
      "→汗っかきの方に多数ご愛用いただいております！肌に張り付かず、不快感なし。お手持ちのウエアの中で一番涼しいとのご感想もいただいております(^^♪",
      "【半袖モックネックと同じサイズでいいですか？】",
      "→細身体型、標準体型の方は同じサイズがオススメです。ガッチリとした体型で半袖モックネックが普段ご着用のサイズよりワンサイズ大きめの方は普段通りのサイズがオススメです。",
      "【ハーフパンツとリブパンツ、セットアップで着用できますか？】",
      "→もちろんです！生地は異なりますが、上下でカッコよく着ていただけるようにお作りしております。",
      "他ご質問などございましたら、LINEからもお問い合わせいただけます！ご相談画面右下の黒丸の吹き出しアイコンよりご利用ください(^^♪",
    ],
    price: "19800",
    sizeImg: "/sldo/size03.webp",
    size: [
      "〈残りわずか〉XS",
      "〈残りわずか〉 S",
      "〈完売〉M",
      "〈完売〉L",
      "〈完売〉XL",
      "〈残りわずか〉XXL",
      "〈残りわずか〉XXXL",
    ],
    images: [
      "/sldo/1003.jpg",
      "/sldo/1003a.jpg",
      "/sldo/1003b.jpg",
      "/sldo/1003c.jpg",
      "/sldo/1003d.jpg",
      "/sldo/1003e.jpg",
    ],
  },
  {
    id: 4,
    name: "CODURA(R) NYLON 4WAY STRETCH サイド切替ハーフパンツ /MATTE BLACK",
    description: [
      "【身長・体重・ウエストサイズ、どのサイズがオススメですか？】",
      "・身長:168cm/体重:67kg/ウエスト:76cm/ガッチリ体型。",
      "→Mサイズでジャストサイズ。Lサイズでゆったりめ。",
      "・身長:170cm/体重:71kg/ガッチリ体型",
      "→Lサイズがオススメです。",
      "・身長:171cm/体重:62kg/細身ながら筋肉質でガッチリ/普段のサイズ:Mサイズ",
      "→Sサイズがオススメです。（上半身がかなりガッチリされてる場合はMサイズ）",
      "・身長:177cm/体重:69kg/やや細身体型",
      "→身長に合わせてLサイズがオススメです。（Mサイズでも身幅は問題ないですが丈が短めになります）",
      "・身長:180cm/体重:75kg/標準体型",
      "→XLサイズがオススメです。",
      "・身長:180cm/体重:80kg/ガッチリ体型",
      "→XXLサイズがオススメです。",
      "・身長:182cm/体重:105kg/ガッチリ体型",
      "→XXXLサイズがオススメです。",
      "【どんな生地感ですか？】",
      "→非常に薄い仕上がりです。通気性、吸水速乾性に優れたサラッとした生地感です。",
      "【汗かきで暑がりです。真夏のラウンドで着用できますか？】",
      "→汗っかきの方に多数ご愛用いただいております！肌に張り付かず、不快感なし。お手持ちのウエアの中で一番涼しいとのご感想もいただいております(^^♪",
      "【半袖モックネックと同じサイズでいいですか？】",
      "→細身体型、標準体型の方は同じサイズがオススメです。ガッチリとした体型で半袖モックネックが普段ご着用のサイズよりワンサイズ大きめの方は普段通りのサイズがオススメです。",
      "【ハーフパンツとリブパンツ、セットアップで着用できますか？】",
      "→もちろんです！生地は異なりますが、上下でカッコよく着ていただけるようにお作りしております。",
      "他ご質問などございましたら、LINEからもお問い合わせいただけます！ご相談画面右下の黒丸の吹き出しアイコンよりご利用ください(^^♪",
    ],
    price: "19800",
    sizeImg: "/sldo/size04.webp",
    size: [
      "S",
      "M",
      "L",
      "〈残りわずか〉XL",
      "〈残りわずか〉XXL",
      "〈完売〉XXXL",
    ],
    images: [
      "/sldo/1004.jpg",
      "/sldo/1004a.jpg",
      "/sldo/1004b.jpg",
      "/sldo/1004c.jpg",
      "/sldo/1004d.jpg",
      "/sldo/1004e.jpg",
      "/sldo/1004f.jpg",
      "/sldo/1004g.jpg",
    ],
  },
  {
    id: 5,
    name: "CODURA(R) NYLON 4WAY STRETCH ハーフパンツ  FRONT BACK LOGO/WHITE",
    description: [
      "【身長・体重・ウエストサイズ、どのサイズがオススメですか？】",
      "・身長:168cm/体重:64kg/標準体型",
      "→Mサイズがオススメです。",
      "・身長:170cm/体重:60kg/細身体型",
      "→Sサイズがオススメです。ゆったり履かれたいときはMサイズもオススメです。",
      "・身長:175cm/体重:68kg/やや細身体型",
      "→Lサイズがオススメです。",
      "・身長:175cm/体重:66kg/やや細身体型、お腹周りが気になる",
      "→Lサイズがオススメです。",
      "・身長:177cm/体重:87kg/ガッチリ体型",
      "→XXLサイズがオススメです。ゆったりめがお好きな場合はXXXLサイズ",
      "・身長:181cm/体重:75kg/ウエスト:83cm/標準体型",
      "→ウエストサイズに合わせてLサイズがオススメです。",
      "【どんな素材感ですか？】",
      "→サラッとドライでマットな質感です。薄手ですがストレッチの効いたしっかりとした素材です。肌に張り付かず涼しく快適です。カジュアルですが、高級感があります。",
      "【黒ハーフパンツと同じ生地ですか？】",
      "→こちらのタイプは黒と同じ生地です。SLDO.の仕様の黄色味のないクリーンな白です。白のみ裏地ありです。",
      "【裏地以外の黒ハーフパンツとの違いがあればを教えてください】",
      "→シルエット、サイズ感は同じです。透け防止を兼ねて前ポケットの深さを変えております。白ハーフパンツの方が4.5cm深めとなっております。",
      "【どのくらいの丈感ですか？膝上で履きたいです】",
      "→一般的なハーフパンツよりも短めです。全サイズ股下の長さはそこまで変わらないので、ウエストに合わせてお選びください。どんな方でも基本的には膝上になる丈感です。",
      "【リブパンツを持っています。同じサイズでいいですか？】",
      "→同じサイズがオススメです。",
      "【涼しいですか？】",
      "→サラッとした質感で真夏を快適にお過ごしいただけます。",
      "他ご質問などございましたら、LINEからもお問い合わせいただけます！ご相談画面右下の黒丸の吹き出しアイコンよりご利用ください(^^♪",
    ],
    price: "18700",
    sizeImg: "/sldo/size05.webp",
    size: [
      "〈完売〉S",
      "M",
      "〈残りわずか〉L",
      "〈残りわずか〉XL",
      "〈完売〉XXL",
      "〈残りわずか〉XXXL",
    ],
    images: [
      "/sldo/1005.jpg",
      "/sldo/1005a.jpg",
      "/sldo/1005b.jpg",
      "/sldo/1005c.jpg",
      "/sldo/1005d.jpg",
      "/sldo/1005e.jpg",
      "/sldo/1005f.jpg",
    ],
  },
  {
    id: 6,
    name: "CODURA(R) NYLON 4WAY STRETCH ハーフパンツ  FRONT BACK LOGO/DARK NAVY",
    description: [
      "【身長・体重・ウエストサイズ、どのサイズがオススメですか？】",
      "・身長:168cm/体重:64kg/標準体型",
      "→Mサイズがオススメです。",
      "・身長:170cm/体重:60kg/細身体型",
      "→Sサイズがオススメです。ゆったり履かれたいときはMサイズもオススメです。",
      "・身長:175cm/体重:68kg/やや細身体型",
      "→Lサイズがオススメです。",
      "・身長:175cm/体重:66kg/やや細身体型、お腹周りが気になる",
      "→Lサイズがオススメです。",
      "・身長:177cm/体重:87kg/ガッチリ体型",
      "→XXLサイズがオススメです。ゆったりめがお好きな場合はXXXLサイズ",
      "・身長:181cm/体重:75kg/ウエスト:83cm/標準体型",
      "→ウエストサイズに合わせてLサイズがオススメです。",
      "【どんな素材感ですか？】",
      "→サラッとドライでマットな質感です。薄手ですがストレッチの効いたしっかりとした素材です。肌に張り付かず涼しく快適です。カジュアルですが、高級感があります。",
      "【どのくらいの丈感ですか？膝上で履きたいです】",
      "→一般的なハーフパンツよりも短めです。全サイズ股下の長さはそこまで変わらないので、ウエストに合わせてお選びください。どんな方でも基本的には膝上になる丈感です。",
      "【リブパンツを持っています。同じサイズでいいですか？】",
      "→同じサイズがオススメです。",
      "【還暦前です。履いていいのでしょうか？】",
      "→着用大歓迎です！シルエットがキレイなカジュアルすぎない大人のハーフパンツとなっております。気になるお腹周りもカバーしてくれるので、年齢を重ねた方にもご満足いただけるパンツです。",
      "他ご質問などございましたら、LINEからもお問い合わせいただけます！ご相談画面右下の黒丸の吹き出しアイコンよりご利用ください(^^♪",
    ],
    price: "17600",
    sizeImg: "/sldo/size06.webp",
    size: ["〈残りわずか〉XXXL"],
    images: [
      "/sldo/1006.jpg",
      "/sldo/1006a.jpg",
      "/sldo/1006b.jpg",
      "/sldo/1006c.jpg",
      "/sldo/1006d.jpg",
    ],
  },
  {
    id: 7,
    name: "【EXTRA SERIES】スウェットクルーネックプルオーバー/ MINIMUM LOGO (UNISEX)",
    description: [
      "・身長:168cm/体重:67kg/ウエスト:76cm/ガッチリ体型。",
      "→Mサイズでジャストサイズ。Lサイズでゆったりめ。",
      "・身長:170cm/体重:71kg/ガッチリ体型",
      "→Lサイズがオススメです。",
      "・身長:171cm/体重:62kg/細身ながら筋肉質でガッチリ/普段のサイズ:Mサイズ",
      "→Sサイズがオススメです。（上半身がかなりガッチリされてる場合はMサイズ）",
      "・身長:177cm/体重:69kg/やや細身体型",
      "→身長に合わせてLサイズがオススメです。（Mサイズでも身幅は問題ないですが丈が短めになります）",
      "・身長:180cm/体重:75kg/標準体型",
      "→XLサイズがオススメです。",
      "・身長:180cm/体重:80kg/ガッチリ体型",
      "→XXLサイズがオススメです。",
      "・身長:182cm/体重:105kg/ガッチリ体型",
      "→XXXLサイズがオススメです。",
      "【どんな生地感ですか？】",
      "→非常に薄い仕上がりです。通気性、吸水速乾性に優れたサラッとした生地感です。",
      "【汗かきで暑がりです。真夏のラウンドで着用できますか？】",
      "→汗っかきの方に多数ご愛用いただいております！肌に張り付かず、不快感なし。お手持ちのウエアの中で一番涼しいとのご感想もいただいております(^^♪",
      "【半袖モックネックと同じサイズでいいですか？】",
      "→細身体型、標準体型の方は同じサイズがオススメです。ガッチリとした体型で半袖モックネックが普段ご着用のサイズよりワンサイズ大きめの方は普段通りのサイズがオススメです。",
      "【ハーフパンツとリブパンツ、セットアップで着用できますか？】",
      "→もちろんです！生地は異なりますが、上下でカッコよく着ていただけるようにお作りしております。",
      "他ご質問などございましたら、LINEからもお問い合わせいただけます！ご相談画面右下の黒丸の吹き出しアイコンよりご利用ください(^^♪",
    ],
    price: "24200",
    sizeImg: "/sldo/size07.webp",
    size: [
      "〈残りわずか〉 S",
      "〈残りわずか〉 M",
      "L",
      "〈残りわずか〉XL",
      "〈残りわずか〉 XXL",
    ],
    images: [
      "/sldo/1007.jpg",
      "/sldo/1007a.jpg",
      "/sldo/1007b.jpg",
      "/sldo/1007c.jpg",
      "/sldo/1007d.jpg",
      "/sldo/1007e.jpg",
      "/sldo/1007f.jpg",
    ],
  },
  {
    id: 8,
    name: "半袖モックネック カットソー FRONT 3D LOGO/BLACK",
    description: [
      "【身長・体重・ウエストサイズ、どのサイズがオススメですか？】",
      "・身長:166cm/体重:70kg/ガッチリ体型/普段のサイズ:Sサイズ",
      "→Mサイズがオススメです。",
      "・身長:170cm/体重:66kg/上半身ガッチリ/普段のサイズ:Lサイズ",
      "→身幅に合わせて、XLサイズがオススメです。",
      "・身長:172cm/体重:67kg/ウエスト:80～82cn/標準体型",
      "→Lサイズがオススメです。",
      "・身長:175cm/体重:79kg/ガッチリ体型",
      "→XXLサイズがオススメです。",
      "・身長:179cm/体重:67kg/細身体型/",
      "→Mサイズがオススメです。",
      "・身長:183cm/体重:87kg/ガッチリ体型",
      "→XXXLサイズがオススメです。",
      "【どんな生地感ですか？黒の半袖モックネックとどう違うのですか？】",
      "→黒に比べて触り心地はドライ感強めです。薄い仕上がりながら高級感があります。ペラペラでもふにゃふにゃでもありません。接触冷感で透け防止機能も備えています。",
      "【リブパンツと同じサイズでいいですか？】",
      "→同じサイズがオススメです。",
      "他ご質問などございましたら、LINEからもお問い合わせいただけます！ご相談画面右下の黒丸の吹き出しアイコンよりご利用ください(^^♪",
    ],
    price: "13200",
    sizeImg: "/sldo/size08.webp",
    size: [
      "〈残りわずか〉XS",
      "〈残りわずか〉 S",
      "〈完売〉M",
      "〈完売〉L",
      "〈完売〉XL",
      "〈残りわずか〉XXL",
      "〈完売〉XXXL",
    ],
    images: [
      "/sldo/1008.jpg",
      "/sldo/1008a.jpg",
      "/sldo/1008b.jpg",
      "/sldo/1008c.jpg",
      "/sldo/1008d.jpg",
      "/sldo/1008e.jpg",
      "/sldo/1008f.jpg",
      "/sldo/1008g.jpg",
    ],
  },
  {
    id: 9,
    name: "【EXTRA SERIES】Tシャツ/MINIMUM LOGO",
    description: [
      "・身長:168cm/体重:67kg/ウエスト:76cm/ガッチリ体型。",
      "→Mサイズでジャストサイズ。Lサイズでゆったりめ。",
      "・身長:170cm/体重:71kg/ガッチリ体型",
      "→Lサイズがオススメです。",
      "・身長:171cm/体重:62kg/細身ながら筋肉質でガッチリ/普段のサイズ:Mサイズ",
      "→Sサイズがオススメです。（上半身がかなりガッチリされてる場合はMサイズ）",
      "・身長:177cm/体重:69kg/やや細身体型",
      "→身長に合わせてLサイズがオススメです。（Mサイズでも身幅は問題ないですが丈が短めになります）",
      "・身長:180cm/体重:75kg/標準体型",
      "→XLサイズがオススメです。",
      "・身長:180cm/体重:80kg/ガッチリ体型",
      "→XXLサイズがオススメです。",
      "・身長:182cm/体重:105kg/ガッチリ体型",
      "→XXXLサイズがオススメです。",
      "【どんな生地感ですか？】",
      "→非常に薄い仕上がりです。通気性、吸水速乾性に優れたサラッとした生地感です。",
      "【汗かきで暑がりです。真夏のラウンドで着用できますか？】",
      "→汗っかきの方に多数ご愛用いただいております！肌に張り付かず、不快感なし。お手持ちのウエアの中で一番涼しいとのご感想もいただいております(^^♪",
      "【半袖モックネックと同じサイズでいいですか？】",
      "→細身体型、標準体型の方は同じサイズがオススメです。ガッチリとした体型で半袖モックネックが普段ご着用のサイズよりワンサイズ大きめの方は普段通りのサイズがオススメです。",
      "【ハーフパンツとリブパンツ、セットアップで着用できますか？】",
      "→もちろんです！生地は異なりますが、上下でカッコよく着ていただけるようにお作りしております。",
      "他ご質問などございましたら、LINEからもお問い合わせいただけます！ご相談画面右下の黒丸の吹き出しアイコンよりご利用ください(^^♪",
    ],
    price: "15400",
    sizeImg: "/sldo/size09.webp",
    size: [
      "〈完売〉 S",
      "〈完売〉 M",
      "〈完売〉 L",
      "〈残りわずか〉XL",
      "〈残りわずか〉XXL",
      "〈残りわずか〉XXXL",
    ],
    images: [
      "/sldo/1009.jpg",
      "/sldo/1009a.jpg",
      "/sldo/1009b.jpg",
      "/sldo/1009c.jpg",
      "/sldo/1009d.jpg",
    ],
  },

  {
    id: 10,
    name: "【EXTRA SERIES】スウェットハーフパンツ/MICRO BOX LOGO",
    description: [
      "【身長・体重・ウエストサイズ、どのサイズがオススメですか？】",
      "・身長:168cm/体重:64kg/標準体型",
      "      →Mサイズがオススメです。",
      "・身長:170cm/体重:60kg/細身体型",
      " →Sサイズがオススメです。ゆったり履かれたいときはMサイズもオススメです。",
      "・身長:175cm/体重:68kg/やや細身体型",
      " →Lサイズがオススメです。",
      "・身長:175cm/体重:66kg/やや細身体型、お腹周りが気になる",
      "→Lサイズがオススメです。",
      "・身長:177cm/体重:87kg/ガッチリ体型",
      "→XXLサイズがオススメです。ゆったりめがお好きな場合はXXXLサイズ",
      "・身長:181cm/体重:75kg/ウエスト:83cm/標準体型",
      "→ウエストサイズに合わせてLサイズがオススメです。",
      "【どんな素材感ですか？】",
      "→サラッとドライでマットな質感です。薄手ですがストレッチの効いたしっかりとした素材です。肌に張り付かず涼しく快適です。カジュアルですが、高級感があります。",
      "【どのくらいの丈感ですか？膝上で履きたいです】",
      "→一般的なハーフパンツよりも短めです。全サイズ股下の長さはそこまで変わらないので、ウエストに合わせてお選びください。どんな方でも基本的には膝上になる丈感です。",
      "【CORURA(R) NYLON ハーフパンツを持っています。同じサイズでいいですか？】",
      " →同じサイズがオススメです。",
      "他ご質問などございましたら、LINEからもお問い合わせいただけます！",
      "ご相談画面右下の黒丸の吹き出しアイコンよりご利用ください(^^♪",
    ],
    price: "19800",
    sizeImg: "/sldo/size010.webp",
    size: [
      "〈完売〉 S",
      "〈完売〉 M",
      "〈完売〉 L",
      "〈残りわずか〉XL",
      "〈残りわずか〉XXL",
      "〈残りわずか〉XXXL",
    ],
    images: [
      "/sldo/10010.jpg",
      "/sldo/10010a.jpg",
      "/sldo/10010b.jpg",
      "/sldo/10010c.jpg",
      "/sldo/10010d.jpg",
      "/sldo/10010e.jpg",
      "/sldo/10010f.jpg",
      "/sldo/10010g.jpg",
    ],
  },
  {
    id: 11,
    name: "【EXTRA SERIES】ライト ダブルニット トラックパンツ/MINIMUM LOGO (UNISEX)",
    description: [
      "・身長:168cm/体重:67kg/ウエスト:76cm/ガッチリ体型。",
      "→Mサイズでジャストサイズ。Lサイズでゆったりめ。",
      "・身長:170cm/体重:71kg/ガッチリ体型",
      "→Lサイズがオススメです。",
      "・身長:171cm/体重:62kg/細身ながら筋肉質でガッチリ/普段のサイズ:Mサイズ",
      "→Sサイズがオススメです。（上半身がかなりガッチリされてる場合はMサイズ）",
      "・身長:177cm/体重:69kg/やや細身体型",
      "→身長に合わせてLサイズがオススメです。（Mサイズでも身幅は問題ないですが丈が短めになります）",
      "・身長:180cm/体重:75kg/標準体型",
      "→XLサイズがオススメです。",
      "・身長:180cm/体重:80kg/ガッチリ体型",
      "→XXLサイズがオススメです。",
      "・身長:182cm/体重:105kg/ガッチリ体型",
      "→XXXLサイズがオススメです。",
      "【どんな生地感ですか？】",
      "→非常に薄い仕上がりです。通気性、吸水速乾性に優れたサラッとした生地感です。",
      "【汗かきで暑がりです。真夏のラウンドで着用できますか？】",
      "→汗っかきの方に多数ご愛用いただいております！肌に張り付かず、不快感なし。お手持ちのウエアの中で一番涼しいとのご感想もいただいております(^^♪",
      "【半袖モックネックと同じサイズでいいですか？】",
      "→細身体型、標準体型の方は同じサイズがオススメです。ガッチリとした体型で半袖モックネックが普段ご着用のサイズよりワンサイズ大きめの方は普段通りのサイズがオススメです。",
      "【ハーフパンツとリブパンツ、セットアップで着用できますか？】",
      "→もちろんです！生地は異なりますが、上下でカッコよく着ていただけるようにお作りしております。",
      "他ご質問などございましたら、LINEからもお問い合わせいただけます！ご相談画面右下の黒丸の吹き出しアイコンよりご利用ください(^^♪",
    ],
    price: "26400",
    sizeImg: "/sldo/size011.webp",
    size: [
      "XS",
      "〈残りわずか〉 S",
      "〈完売〉M",
      "〈残りわずか〉L",
      "〈残りわずか〉XL",
      "〈残りわずか〉XXL",
      "〈完売〉XXXL",
    ],
    images: [
      "/sldo/10011.jpg",
      "/sldo/10011a.jpg",
      "/sldo/10011b.jpg",
      "/sldo/10011c.jpg",
      "/sldo/10011d.jpg",
      "/sldo/10011e.jpg",
      "/sldo/10011f.jpg",
    ],
  },
  {
    id: 12,
    name: "SLDO. × AddElm ダブルクロスストレッチプルオーバー MATTE BLACK (UNISEX)",
    description: [
      "【スタッフ着用コメント】",
      "《スタッフC（MEN）》",
      "身長:168cm/体重:64kg/ウエスト：78cm/普段サイズ:M/着用サイズ:M",
      "サイズ感：インナーを中に合わせて、Mサイズでジャストサイズ",
      "素材感：表面はサラっと適度なハリのある、とてもストレッチの効いた素材",
      "着心地：適度な身幅があって動きやすい。スイングしやすい。",
      "《スタッフD（MEN）》",
      "身長：168cm /体重：73kg /ウエスト： 86cm /股下：64cm/ 普段サイズ：L / 着用サイズ：L",
      "サイズ感：普段はMかLサイズ。Lサイズでちょうどでした。",
      "素材感：上品な素材感。スウェットのような素材に見えるが全く異なる高級感素材",
      "着心地：袖の通りがいいおかげでサッと着やすい。",
      "《スタッフE（MEN）》",
      "身長：178cm /体重：105kg /ウエスト： 105cm / 普段サイズ：1XL / 着用サイズ：XXL",
      "サイズ感：冬は重ね着をするのでXXLサイズに",
      "素材感：しなやかながらタフな素材感。いい意味で季節感を感じさせない。",
      "着心地：着脱しやすい！ストレッチが効いているので動きが妨げられません",
      "《スタッフG（MEN）》",
      "身長:190cm/体重:90kg/普段サイズ:XL/着用サイズ:XXL",
      "サイズ感：ガッチリしているのでXXLサイズに。少しゆとりがあって丈はジャスト。",
      "素材感：軽量でストレッチが効いていて着やすい",
      "着心地：生地の厚みを抑えてくれているので見た目スッキリ。肩幅の設定がないので圧迫感がない",
    ],
    price: "24200",
    sizeImg: "/sldo/size012.webp",
    size: ["S", "〈完売〉M", "L", "XL", "XXL", "XXXL"],
    images: ["/sldo/10012.jpg", "/sldo/10012a.jpg", "/sldo/10012b.jpg"],
  },
  {
    id: 13,
    name: "【EXTRA LIMITED】裏起毛フーディー/MINIMUM BLACK LOGO (UNISEX)",
    description: [
      "【スタッフ着用コメント】",
      "《スタッフC（MEN）》",
      "身長:168cm/体重:64kg/ウエスト：78cm/普段サイズ:M/着用サイズ:M",
      "サイズ感：インナーを中に合わせて、Mサイズでジャストサイズ",
      "素材感：表面はサラっと適度なハリのある、とてもストレッチの効いた素材",
      "着心地：適度な身幅があって動きやすい。スイングしやすい。",
      "《スタッフD（MEN）》",
      "身長：168cm /体重：73kg /ウエスト： 86cm /股下：64cm/ 普段サイズ：L / 着用サイズ：L",
      "サイズ感：普段はMかLサイズ。Lサイズでちょうどでした。",
      "素材感：上品な素材感。スウェットのような素材に見えるが全く異なる高級感素材",
      "着心地：袖の通りがいいおかげでサッと着やすい。",
      "《スタッフE（MEN）》",
      "身長：178cm /体重：105kg /ウエスト： 105cm / 普段サイズ：1XL / 着用サイズ：XXL",
      "サイズ感：冬は重ね着をするのでXXLサイズに",
      "素材感：しなやかながらタフな素材感。いい意味で季節感を感じさせない。",
      "着心地：着脱しやすい！ストレッチが効いているので動きが妨げられません",
      "《スタッフG（MEN）》",
      "身長:190cm/体重:90kg/普段サイズ:XL/着用サイズ:XXL",
      "サイズ感：ガッチリしているのでXXLサイズに。少しゆとりがあって丈はジャスト。",
      "素材感：軽量でストレッチが効いていて着やすい",
      "着心地：生地の厚みを抑えてくれているので見た目スッキリ。肩幅の設定がないので圧迫感がない",
    ],
    price: "19800",
    sizeImg: "/sldo/size013.webp",
    size: [
      "〈完売〉S",
      "〈残りわずか〉M",
      "〈完売〉L",
      "〈残りわずか〉XL",
      "〈残りわずか〉XXL",
    ],
    images: [
      "/sldo/10013.jpg",
      "/sldo/10013a.jpg",
      "/sldo/10013b.jpg",
      "/sldo/10013c.png",
      "/sldo/10013d.jpg",
      "/sldo/10013e.jpg",
    ],
  },
  {
    id: 14,
    name: "【EXTRA SERIES】スウェットクルーネックプルオーバー/ MINIMUM LOGO (UNISEX)",
    description: [
      "・身長:168cm/体重:67kg/ウエスト:76cm/ガッチリ体型。",
      "→Mサイズでジャストサイズ。Lサイズでゆったりめ。",
      "・身長:170cm/体重:71kg/ガッチリ体型",
      "→Lサイズがオススメです。",
      "・身長:171cm/体重:62kg/細身ながら筋肉質でガッチリ/普段のサイズ:Mサイズ",
      "→Sサイズがオススメです。（上半身がかなりガッチリされてる場合はMサイズ）",
      "・身長:177cm/体重:69kg/やや細身体型",
      "→身長に合わせてLサイズがオススメです。（Mサイズでも身幅は問題ないですが丈が短めになります）",
      "・身長:180cm/体重:75kg/標準体型",
      "→XLサイズがオススメです。",
      "・身長:180cm/体重:80kg/ガッチリ体型",
      "→XXLサイズがオススメです。",
      "・身長:182cm/体重:105kg/ガッチリ体型",
      "→XXXLサイズがオススメです。",
      "【どんな生地感ですか？】",
      "→非常に薄い仕上がりです。通気性、吸水速乾性に優れたサラッとした生地感です。",
      "【汗かきで暑がりです。真夏のラウンドで着用できますか？】",
      "→汗っかきの方に多数ご愛用いただいております！肌に張り付かず、不快感なし。お手持ちのウエアの中で一番涼しいとのご感想もいただいております(^^♪",
      "【半袖モックネックと同じサイズでいいですか？】",
      "→細身体型、標準体型の方は同じサイズがオススメです。ガッチリとした体型で半袖モックネックが普段ご着用のサイズよりワンサイズ大きめの方は普段通りのサイズがオススメです。",
      "【ハーフパンツとリブパンツ、セットアップで着用できますか？】",
      "→もちろんです！生地は異なりますが、上下でカッコよく着ていただけるようにお作りしております。",
      "他ご質問などございましたら、LINEからもお問い合わせいただけます！ご相談画面右下の黒丸の吹き出しアイコンよりご利用ください(^^♪",
    ],
    price: "24200",
    sizeImg: "/sldo/size014.webp",
    size: [
      "〈残りわずか〉 S",
      "〈残りわずか〉M",
      "L",
      "〈残りわずか〉XL",
      "〈残りわずか〉XXL",
    ],
    images: [
      "/sldo/10014.jpg",
      "/sldo/10014a.jpg",
      "/sldo/10014b.jpg",
      "/sldo/10014c.jpg",
      "/sldo/10014d.jpg",
    ],
  },
];

export default productData;
