import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faGear,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

function About() {
  return (
    <div className="main-container">
      <Header />
      <div className="twoDiv">
        <img alt="" src="/cloth/vedio.png" />
      </div>
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <h2>We're E-Cloth</h2>
          <p>
            For more than 20 years, we’ve made premium cleaning materials using
            superior textiles and exceptional engineering.
          </p>
          <p>
            You want cleaning products that work. We call that
            <strong className="p-str"> Effective.</strong>
          </p>
          <p>
            You care about quality. We call that
            <strong className="p-str"> Exceptional Engineering.</strong>
          </p>
          <p>
            You want fewer chemicals and don’t want to pollute your family,
            pets, or the world. We call that
            <strong className="p-str"> Environmentally Friendly.</strong>
          </p>
        </div>
      </div>
      <div className="twoDiv">
        <img alt="" src="/cloth/about1.jpg" />
      </div>
      <div className="contactAll">
        <div className="contactLeft">
          <h2>Welcome.</h2>
          <p>
            The story of our reusable, chemical-free cloths started more than
            twenty years ago with a guy named Steve Pearson. Intrigued by the
            idea of cleaning without chemicals, Steve set out to manufacture the
            world’s best natural cleaning system.
          </p>
          <p>
            He started humbly, in a one-room office with a cardboard box filled
            with 300 premium quality microfibres, determined to bring the
            eco-friendly cleaning concept to as many homes as possible.
          </p>
          <p>
            The more he learned, the more his passion grew. To help him create
            the best cleaning cloth possible, Steve teamed up with an engineer
            and scientists. The results of that collaboration were E-Cloth’s
            <strong className="p-str">General Purpose Cloth</strong> and
            <strong className="p-str">Glass and Polishing Cloth.</strong>
          </p>
          <p>
            Initial tests on popular household appliances like Neff, Miele, and
            Electrolux proved how well the cleaning cloths worked on stainless
            steel. A brand was born!
          </p>
          <p>
            Although E-Cloth has added dozens of specialised products, those
            flagship cloths are still purchased by millions of customers each
            year, revered for their unsurpassed ability to clean without toxic
            chemicals.
          </p>
          <p>
            Through the years, E-Cloth has remained committed to the ideal of
            producing reusable, chemical-free cloths that clean better than
            anything else.
          </p>
          <p>
            We continually adapt and improve our engineering and have added new
            task-specific products to tackle unique surfaces (like your cell
            phone), always maintaining our high-quality standards and
            chemical-free policy.
          </p>
          <p>
            Today, you can clean your floors, dishes, windows, stainless steel
            appliances, and even your car sustainably and without chemicals when
            you use E-Cloth products.
          </p>
          <p>
            What started as one man’s vision – chemical-free cleaning that
            really cleans – has become a lifestyle choice for many around the
            globe. As more people have become intentional about embracing
            sustainability, E-Cloth is proud to be the partner that helps keep
            your home, gadgets, and car clean, without chemicals and unnecessary
            waste. If you’re new around here, welcome to the E-Cloth family!
          </p>
        </div>
      </div>
      <div className="contactAll">
        <div className="contactLeft">
          <h2>Read more about E-Cloth</h2>
          <div className="read-three">
            <div className="read-one">
              <h4>How E-Cloth is Made</h4>
              <p>Find out why E-Cloth is different from other microfibre.</p>
            </div>
            <div className="read-one">
              <h4>Benefits of E-Cloth</h4>
              <p>Read about the benefits of chemical-free cleaning.</p>
            </div>
            <div className="read-one">
              <h4>The Scientific Proof</h4>
              <p>Backed by lab tests and clean homes. Here's the proof.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="person-img">
        <img alt="" src="/cloth/person.svg" />
      </div>
      <div className="crop-svg">
        <div className="cropSvg">
          <FontAwesomeIcon icon={faComments} />
          <p class="icon-bar__text ">chat</p>
        </div>
        <div className="cropSvg">
          <FontAwesomeIcon icon={faEnvelope} />
          <p class="icon-bar__text ">email</p>
        </div>
        <div className="cropSvg">
          <FontAwesomeIcon icon={faPhone} />
          <p class="icon-bar__text ">call</p>
        </div>
        <div className="cropSvg">
          <FontAwesomeIcon icon={faGear} />
          <p class="icon-bar__text ">FAQ</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
